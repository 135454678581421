import '../../../components/nova-flow/nova-flow/nova-flow.js';
import '../../../components/nova-flow/nova-flow-step/nova-flow-step.js';
import './steps/client-onboarding-industry/client-onboarding-industry.js';
import './steps/client-onboarding-department/client-onboarding-department.js';
import './steps/client-onboarding-skills/client-onboarding-skills.js';
import './steps/client-onboarding-welcome/client-onboarding-welcome.js';
import './steps/client-onboarding-add-users/client-onboarding-add-users.js';
import '../../../../shared/components/nova-animation/nova-animation.js';
import '../view-client-onboarding/client-onboarding-progress-bar/client-onboarding-progress-bar.js';

import { css, html, LitElement } from 'lit';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';

import { LocalizeNova } from '../../../../shared/mixins/localize-nova/localize-nova.js';
import { NovaNavMixin } from '../../../../shared/mixins/nova-nav/nova-nav.js';
import { OBJECTIVES } from './steps/client-onboarding-objective/client-onboarding-objective.js';

const STEPS = {
  START: {
    name: 'welcome',
    displayName: 'Welcome!',
  },
  OBJECTIVE: {
    name: 'objective',
    displayName: 'Who\'s Learning',
  },
  INDUSTRY: {
    name: 'industry',
    displayName: 'Industry',
  },
  DEPARTMENT: {
    name: 'department',
    displayName: 'Department',
  },
  SKILLS: {
    name: 'skills',
    displayName: 'Skills',
  },
  ADD_USERS: {
    name: 'add-users',
    displayName: 'Add Users',
  },
  END: {
    name: 'end',
    displayName: 'End',
  },
};

export default class ViewClientOnboarding extends LocalizeNova(RequesterMixin(NovaNavMixin(LitElement))) {

  static get properties() {
    return {
      _companyName: { type: String },
      _lightcastCompanyId: { type: String },
      _useButtonBar: { type: Boolean },
    };
  }

  static get styles() {
    return [
      css`
      :host {
        display: block;
        height: 100%;
        width: 100%;
      }
      .client-onboarding-template-container {
        display: flex;
        height: 100%;
        width: 100%;
      }

      .client-onboarding-template-primary {
        display: flex;
        flex: 1;
        flex-direction: column;
      }

      .client-onboarding-template-secondary {
        display: block;
        flex-shrink: 0;
        max-width: 25%;
        overflow: hidden;
        width: 100%;
      }
      .client-onboarding-template-secondary img {
        height: 100%;
        width: 100%;
      }

      .logo-container {
        align-items: flex-start;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 80px;
        max-height: 80px;
        padding-top: 14px;
      }
      .logo-container img {
        margin: 13px 0 0 32px;
        max-width: 120px;
      }
      .logo-container nova-lang-select-button {
        margin-right: 20px;
      }

      .client-onboarding-template-primary-header {
        display: flex;
        flex-direction: column;
      }

      .client-onboarding-template-primary-content {
        display: flex;
        flex: 1;
        justify-content: center;
      }
      .client-onboarding-template-primary-content .content {
        max-width: 750px;
      }

      @media (max-width: 1024px) {
        .client-onboarding-template-secondary {
          display: none;
        }
      }
    `];
  }

  constructor() {
    super();
    this._companyName = '';
    this._useButtonBar = true;
    this._activeStepConfig = undefined;
  }

  async connectedCallback() {
    super.connectedCallback();
    this.client = this.requestInstance('d2l-nova-client');
    this.session = this.requestInstance('d2l-nova-session');

    this._companyName = this.session.tenant.name;
    // TODO: Leaving this for testing, will remove later
    this._companyName = 'D2L';
    const lightcastCompanyData = await this.client.searchLightcast(this._companyName);
    if (lightcastCompanyData.length) {
      this._lightcastCompanyId = lightcastCompanyData[0].id;
    }

    this.dispatchEvent(new CustomEvent('nova-hide-freshchat-widget', { bubbles: true, composed: true }));
  }

  disconnectedCallback() {
    document.dispatchEvent(new CustomEvent('nova-show-freshchat-widget', { bubbles: true, composed: true }));
    super.disconnectedCallback();
  }

  async _completedFlow(e) {
    const data = e.target.data;

    const promises = [];
    let industrySkills, departmentSkills;

    if (data[STEPS.INDUSTRY.name] && data[STEPS.INDUSTRY.name].industryList?.length) {
      promises.push(this.client.lightcastSkills({
        companyId: this._lightcastCompanyId,
        skillCategories: data[STEPS.INDUSTRY.name].industryList,
      }).then(result => industrySkills = result.skills));
    }
    if (data[STEPS.DEPARTMENT.name] && data[STEPS.DEPARTMENT.name].department) {
      promises.push(this.client.lightcastSkills({
        companyId: this._lightcastCompanyId,
        lotCareerArea: data[STEPS.DEPARTMENT.name].department,
      }).then(result => departmentSkills = result.skills));
    }

    await Promise.all(promises);

    const streams = {};

    if (industrySkills && industrySkills.length) {
      streams.industryStream = {
        displayName: 'Recommended for your company',
        path: 'tenant-onboarding-industry',
        skills: industrySkills,
      };
    }
    if (departmentSkills && departmentSkills.length) {
      streams.departmentStream = {
        displayName: 'Recommended for your department',
        path: 'tenant-onboarding-department',
        skills: departmentSkills,
      };
    }

    this.client.createStreams(streams);

    this.navigateWithoutHistory('/');
  }

  _shouldSkipDepartment(data) {
    return data[STEPS.OBJECTIVE.name].objective !== OBJECTIVES.MY_TEAM;
  }

  render() {
    return html`
      <div class="client-onboarding-template-container">
        <div class="client-onboarding-template-primary">
          <div class="client-onboarding-template-primary-header">
            <div class="logo-container">
              <img id="skillswave-logo" src="/assets/img/skillswave-logo-black.svg" alt="Skillswave Logo" />
              <nova-lang-select-button></nova-lang-select-button>
            </div>
          </div>
          <div class="client-onboarding-template-primary-content">
            <div class="content">
              <client-onboarding-progress-bar>
                <nova-flow @completed=${this._completedFlow} ?hideProgressBar=${true} ?hideNavigationButtons=${true} name="client-onboarding">
                  <client-onboarding-welcome
                    .step=${STEPS.START}
                    next-enabled
                  ></client-onboarding-welcome>
                  <client-onboarding-objective
                    .step=${STEPS.OBJECTIVE}
                  ></client-onboarding-objective>
                  <client-onboarding-industry
                    .step=${STEPS.INDUSTRY}
                    lightcast-company-id="${this._lightcastCompanyId}">
                  </client-onboarding-industry>
                  <client-onboarding-department
                    .step=${STEPS.DEPARTMENT}
                    .shouldSkip=${this._shouldSkipDepartment}
                    lightcast-company-id="${this._lightcastCompanyId}">
                  </client-onboarding-department>
                  <client-onboarding-skills
                    .step="${STEPS.SKILLS}"
                    lightcast-company-id="${this._lightcastCompanyId}"
                    next-enabled>
                  </client-onboarding-skills>
                  <client-onboarding-add-users
                    .step="${STEPS.ADD_USERS}"
                    next-enabled>
                  </client-onboarding-add-users>
                  <nova-flow-step .step=${STEPS.END} next-enabled>
                    <div>
                      <h1>Welcome to a world of learning</h1>
                      <nova-animation src="/assets/rive/success_page.riv" height='400' width='400'></nova-animation>
                      <d2l-button id="nova-flow-finish" primary>${this.localize('general.button-text.finish')}</d2l-button>
                    </div>
                  </nova-flow-step>
                </nova-flow>
              </client-onboarding-progress-bar>
            </div>
          </div>
        </div>
        <div class="client-onboarding-template-secondary">
          <img src="/assets/img/client-onboarding-side-panel.svg" alt="Decorative side panel" />
        </div>
      </div>
    `;
  }
}

window.customElements.define('view-client-onboarding', ViewClientOnboarding);
